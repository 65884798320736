import React from 'react'
import Speaking1 from './Speaking1'
import Speaking2 from './Speaking2'
import Speaking3 from './Speaking3'


function Part3() {
  return (
    <div className='Container'>
       <div className='pb-[50px]'>
       <Speaking1/>
        <Speaking2/>
        <Speaking3/>
       </div>
    </div>
  )
}

export default Part3